import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "vuetify/lib/locale/en";
import pt from "vuetify/lib/locale/pt";

Vue.use(VueI18n);

const messages = {
  en: {
    $vuetify: en,
    menus: {
      fxmarket: "FX Market Monitor",
      trading: "Trading Grid",
      reports: "Reporting"
    },
    title: {
      trading: "Trading Grid",
      reportDashboard: "Reporting",
      marketMonitor: "FX Market Monitor"
    },
    quoteInfo: {
      side: "Side",
      ccy: "CCY",
      settlement: "Settlement",
      dealTime: "Deal Time",
      amount: "Amount",
      bankName: "Bank Name",
      id: "ID",
      amountCcy: "Amount (CCY)",
      confirmation: "Confirmation",
      succesDeal: "Success Deal",
      cancelledOrder: "Unsuccessfull Deal",
      cancelledDeal: "Deal Cancelled",
      loadingOrder: "Loading Order",
      lastUpdate: "Last update",
      timeDue: "Time Due",
      bank: "Bank",
      rate: "Rate",
      timeLeft: "Time Left",
      buy: "BUY",
      sell: "SELL",
      bestRate: "Best Rate",
      spreadAvg: "Spread (AVG Spot)",
      spreadPtax: "Spread (PTAX)",
      total: "Total",
      cfxIndex: "CFX Index",
      bankLastUpdate: "Bank Last Update",
      bankTimeDue: "Bank Time Due",
      status: "Status",
      bankRate: "Bank Rate"
    },
    buyDialog: {
      title: "Get Grid PRO by clearfx.ai",
      instruction: {
        instructionOne: "To confirm the quote, you need to be a Grid PRO user.",
        instructionTwo: "With Grid PRO, you'll be able to complete your transactions, select your preferred bank, and access all premium features that make managing your finances easier.",
        instructionThree: "Should you find that Grid PRO aligns with your needs, it offers additional features designed to support your financial needs."
      },
      cancel: "cancel",
      getGridpro: "Get Grid PRO",
    },
    reportDialog: {
      title: "Get Grid PRO by clearfx.ai",
      instruction: {
        instructionOne: "To access detailed reports, you need to be a Grid PRO user.",
        instructionTwo: "With Grid PRO, you'll have access to comprehensive Reports, view PDFs of all your quotes, and utilize all the premium features that simplify your financial management.",
        instructionThree: "Should you find that Grid PRO aligns with your needs, it offers additional features designed to enhance your financial experience.",
      },
      reportsView: "Reports View:",
      reportsPdf: "Reports PDF:",
      back: "Back",
      getGridPro: "Get Grid PRO"
    },
    side: {
      buy: "Buy",
      sell: "Sell"
    },
    quote: {
      quote: "Quote",
    },
    instructions: {
      accept: "Accept",
      decline: "Decline",
      cancel: "Cancel",
      confirm: "Confirm",
      close: "Close",
      expandAll: "Expand All",
      contractAll: "Contract All",
      filter: "Filter",
      exportSpreadsheet: "Export Spreadsheet",
      exportPdf: "Export PDF",
      back: "Back",
      login: "Log In",
      logout: "Logout",
      next: "Next"
    },
    extras: {
      loading: "Loading",
      loadingDot: "Loading...",
      or: "or",
      at: "at",
      start: "Start",
      end: "End",
      language: "Language",
      footer: "© 2023-2024 clearfx.ai All rights reserved.",
      expSnackbar: "Sorry, the RFQ time has been expired.",
      cancelQuoteSnackbar: "Sorry, but the RFQ was cancelled by the bank. Contact support if it persists."
    },
    toolbar: {
      avgPtax: "AVG PTAX",
      timeZone: "Time Zone"
    },
    requestHelp: {
      requestHelp: "Request Help",
      greeting: "Hi, how can we help you?",
      instruction: "Select how you would like to receive help from our Customer Representatives",
      sendEmail: {
        title: "Send Us An Email",
        btnText: "Send Email"
      },
      helpTicket: {
        title: "Submit A Help Ticket",
        description: "We will respond as soon as possible",
        btnText: "Start Ticket (TBR)"
      },
      liveChat: {
        title: "Live Chat",
        btnText: "Chat Now (TBR)"
      }
    },
    timestamp: {
      today: "Today",
      oneDay: "1D",
      oneWeek: "1W",
      oneMonth: "1M"
    },
    marketMonitor: {
      dollar: "Dollar",
      trend: "Trend",
      target: "Target",
      goodTo: "Good to",
      news: "News",
      crossQuotes: "Cross Quotes",
      economicCalendar: "Economic Calendar",
      dollarRadar: {
        title: "USD Radar",
        strongSell: "Strong High",
        strongBuy: "Strong Low",
        buy: "High",
        sell: "Low",
        neutral: "Neutral"
      },
      period: {
        title: "Period",
        endWeek: "End of the week",
        tenDays: "10 days",
        twentyDays: "20 days",
        oneMonth: "1 month"
      }
    },
    economicCalendar: {
      date: "Time",
      country: "Country",
      importance: "Importance",
      title: "Title",
      actual: "Actual",
      forecast: "Forecast",
      previous: "Previous",
      expand: "Expand",
      close: "Close",
      today: "Today",
      tomorrow: "Tomorrow",
    },
    notFound: {
      title: "404 Page not Found",
      descriptionOne: "I'm afraid you've found a page that doesn't exist on Clearfxai.",
      descriptionTwo: "That can happen when you follow a link to something that has since been deleted.",
      descriptionThree: "Or the link was incorrect to begin with.",
      apologies: "Sorry about that.",
    },
    landing: {
      title: "Grid",
      description: {
        descriptionOne: "Log in to access a liquidity pool formed by multiple",
        descriptionTwo: "FX market-making institutions. Execute orders, export trade",
        descriptionThree: "history, track trends with our innovative FX Market",
        descriptionFour: "Monitor, and more.",
      },
      fxMarket: {
        title: "FX Market Monitor",
        descriptionOne: "Access all the essentials needed to make informed trading",
        descriptionTwo: "decisions: real-time market data, news headlines, and an",
        descriptionThree: "economic calendar, all tailored specifically to meet the",
        descriptionFour: "needs of small and medium-sized businesses and streamline",
        descriptionFive: "their FX activities.",
      },
      gridPro: {
        title: "clearfx.ai | Grid PRO",
        descriptionOne: "Grid PRO enables to dispatch requests for quotes to multiple",
        descriptionTwo: "institutions and generates detailed trade history reports",
        descriptionThree: "with comprehensive quotes, ensuring optimal compliance",
        descriptionFour: "requirements.",
      },
      algorithmic: {
        title: "Advanced Algorithmic Execution",
        descriptionOne: "Best-Rate Algo",
        descriptionTwo: ": Automatically confirm orders at the",
        descriptionThree: "best rate within a specified timeframe.",
        descriptionFour: "Sched Algo",
        descriptionFive: ": Schedule orders to target a rate with an",
        descriptionSix: "expiration timestamp. Log in for more."  
      },
      footer: "© 2023-2024 clearfx.ai All rights reserved."
    },
    login: {
      title: "Login",
      description: {
        descriptionOne: "This is a secure clearfx.ai authentication service",
        descriptionTwo: "that allows you access to clearfx.ai services",
        descriptionThree: "from wherever you are.",
      },
      corporateEmail: "Coporate Email",
      password: "Password",
      forgotPassword: "Forgot Password",
      invalidCredentials: "User authentication failed: invalid credentials"
    },
    reports: {
      expand: {
        title: "Details",
        algorithm: "Algorithm",
        cfxIndex: "CFX Index",
        bestRate: "Best rate",
        side: "Side",
        rates: {
          title: "Rates",
          accepted: "Accepted",
          declined: "Declined"
        }
      }
    },
    pdf: {
      title: "Report",
      accepted: "Accepted",
      decliend: "Declined"
    }
  },
  pt: {
    $vuetify: pt,
    menus: {
      fxmarket: "Mercado FX",
      trading: "Negociação",
      reports: "Relatórios"
    },
    title: {
      trading: "Negociação Grid",
      reportDashboard: "Painel de Relatórios",
      marketMonitor: "Monitor de Mercado"
    },
    quoteInfo: {
      side: "Sentido",
      ccy: "Moeda",
      settlement: "Prazo",
      dealTime: "Horário da Negociação",
      amount: "Montante",
      bankName: "Nome do banco",
      id: "ID",
      amountCcy: "Montante (Moeda)",
      confirmation: "Confirmação",
      succesDeal: "Negociado com sucesso",
      cancelledOrder: "Negociação cancelada",
      cancelledDeal: "Negociação mal sucedida ",
      loadingOrder: "Carregando Pedido",
      lastUpdate: "Última Atualização",
      timeDue: "Expiração do preço",
      bank: "Banco",
      rate: "Taxa",
      timeLeft: "Tempo Restante",
      buy: "COMPRA",
      sell: "VENDA",
      bestRate: "Melhor Taxa",
      spreadAvg: "Spread (Média Spot)",
      spreadPtax: "Spread (PTAX)",
      total: "Total",
      cfxIndex: "CFX Index",
      bankLastUpdate: "Última atualização do banco",
      bankTimeDue: "Tempo Final do Banco",
      status: "Status",
      bankRate: "Taxa do Banco",
    },
    buyDialog: {
      title: "Obtenha o Grid PRO da clearfx.ai",
      instruction: {
        instructionOne: "Para confirmar a cotação, você precisa ser um usuário Grid PRO.",
        instructionTwo: "Com o Grid PRO, você poderá concluir suas transações, selecionar seu banco preferido e acessar todos os recursos premium que facilitam o gerenciamento de suas finanças.",
        instructionThree: "Caso você considere que o Grid PRO atende às suas necessidades, ele oferece recursos adicionais projetados para dar suporte às suas necessidades financeiras."
      },
      cancel: "Cancelar",
      getGridpro: "Adquirir Grid PRO"
    },
    reportDialog: {
      title: "Obtenha o Grid PRO da clearfx.ai",
      instruction: {
        instructionOne: "Para acessar relatórios detalhados, você precisa ser um usuário do Grid PRO.",
        instructionTwo: "Com o Grid PRO, você terá acesso a relatórios abrangentes, visualizará PDFs de todas as suas cotações e utilizará todos os recursos premium que simplificam sua gestão financeira.",
        instructionThree: "Caso você considere que o Grid PRO atende às suas necessidades, ele oferece recursos adicionais projetados para melhorar sua experiência financeira.",
      },
      reportsView: "Tela de Relatórios:",
      reportsPdf: "PDF dos Relatórios:",
      back: "Voltar",
      getGridPro: "Adquirir Grid PRO"
    },
    side: {
      buy: "Comprar",
      sell: "Vender"
    },
    bankTable: {
      bestRate: "Melhor Taxa",
      bank: "Banco",
      rate: "Taxa",
      spreadAvg: "Spread (Média Spot)",
      spreadPtax: "Spread (PTAX)",
      total: "Total",
      cfxIndex: "CFX Index"
    },
    quote: {
      quote: "Cotar",
    },
    instructions: {
      accept: "Aceitar",
      decline: "Recusar",
      cancel: "Cancelar",
      confirm: "Confirmar",
      close: "Fechar",
      expandAll: "Expandir Tudo",
      contractAll: "Contrair Tudo",
      filter: "Filtrar",
      exportSpreadsheet: "Exportar Planilha",
      exportPdf: "Exportar PDF",
      back: "Voltar",
      login: "Entrar",
      logout: "Sair",
      next: "Continuar"
    },
    extras: {
      loading: "Carregando",
      loadingDot: "Carregando...",
      or: "ou",
      at: "às",
      start: "Início",
      end: "Término",
      language: "Idioma",
      footer: "© 2023-2024 clearfx.ai Todos os direitos reservados.",
      expSnackbar: "Desculpe, o tempo da RFQ expirou.",
      cancelQuoteSnackbar: "Desculpe, mas a RFQ foi cancelada pelo banco. Contate o suporte se persistir."
    },
    toolbar: {
      avgPtax: "Média PTAX",
      timeZone: "Fuso Horário"
    },
    requestHelp: {
      requestHelp: "Pedir Ajuda",
      greeting: "Oi, como podemos ajudar você?",
      instruction: "Selecione como você gostaria de receber ajuda de nossos Representantes",
      sendEmail: {
        title: "Envie um Email",
        btnText: "Enviar Email"
      },
      helpTicket: {
        title: "Envie um Ticket de Ajuda",
        description: "Responderemos o mais rápido possível",
        btnText: "Iniciar Ticket (TBR)"
      },
      liveChat: {
        title: "Chat",
        btnText: "Conversar Agora (TBR)"
      }
    },
    timestamp: {
      today: "Hoje",
      oneDay: "1D",
      oneWeek: "1S",
      oneMonth: "1M"
    },
    marketMonitor: {
      dollar: "Dólar",
      trend: "Tendência",
      target: "Alvo",
      goodTo: "Bom para",
      news: "Notícias",
      crossQuotes: "Cotações Cruzadas",
      economicCalendar: "Calendário Econômico",
      dollarRadar: {
        title: "Radar do Dólar",
        strongSell: "Baixa Forte",
        strongBuy: "Alta Forte",
        buy: "Compra",
        sell: "Venda",
        neutral: "Neutra"
      },
      period: {
        title: "Período",
        endWeek: "Fim da semana",
        tenDays: "10 dias",
        twentyDays: "20 dias",
        oneMonth: "1 mês"
      },
    },
    economicCalendar: {
      date: "Hora",
      country: "País",
      importance: "Relevância",
      title: "Manchete",
      atual: "Atual",
      forecast: "Previsão",
      previous: "Anterior",
      expand: "Expandir",
      close: "Fechar",
      today: "Hoje",
      tomorrow: "Amanhã",
    },
    notFound: {
      title: "404 Página não Encontrada",
      descriptionOne: "Receio que você tenha encontrado uma página que não existe na Clearfxai.",
      descriptionTwo: "Isso pode acontecer quando você segue um link para algo que já foi excluído.",
      descriptionThree: "Ou o link estava incorreto.",
      apologies: "Desculpe por isso.",
    },
    landing: {
      title: "Grid",
      description: {
        descriptionOne: "Faça login para acessar um ecossistema formado por diversas",
        descriptionTwo: "instituições de market-making FX. Execute ordens, exporte o",
        descriptionThree: "histórico de negociações, acompanhe tendências com o nosso inovador Monitor de",
        descriptionFour: "Mercado FX e muito mais.",
      },
      fxMarket: {
        title: "clearfx.ai | Monitor de Mercado FX",
        descriptionOne: "Acesse todos os elementos essenciais para tomar decisões de trading",
        descriptionTwo: "informadas: dados de mercado em tempo real, manchetes de notícias e um",
        descriptionThree: "calendário econômico, todos adaptados especificamente para atender às",
        descriptionFour: "necessidades de pequenas e médias empresas e agilizar",
        descriptionFive: "suas atividades FX.",
      },
      gridPro: {
        title: "Grid PRO",
        descriptionOne: "O Grid PRO permite solicitações simultâneas de cotações de várias ",
        descriptionTwo: "instituições e gera relatórios detalhados do histórico de negociações",
        descriptionThree: "com cotações completas, garantindo a conformidade ideal com os",
        descriptionFour: "requisitos.",
      },
      algorithmic: {
        title: "Algorítmico de Execução Avançado",
        descriptionOne: "Algoritmo de Melhor Taxa",
        descriptionTwo: ": Confirme ordens automaticamente na melhor taxa",
        descriptionThree: "dentro de um período especificado.",
        descriptionFour: "Algoritmo de Agendamento",
        descriptionFive: ": Programe ordens para atingir uma taxa com um",
        descriptionSix: "carimbo de data/hora de expiração. Acesse para saber mais.",
      },
    },
    login: {
      title: "Login",
      description: {
        descriptionOne: "Este é um serviço de autenticação seguro do clearfx.ai",
        descriptionTwo: "que permite o acesso aos serviços do clearfx.ai",
        descriptionThree: "de qualquer lugar.",
      },
      corporateEmail: "Email Corporativo",
      password: "Senha",
      forgotPassword: "Esqueceu a senha",
      invalidCredentials: "Falha na autenticação do usuário: credenciais inválidas"
    },
    reports: {
      expand: {
        title: "Detalhes",
        algorithm: "Algorítmo",
        cfxIndex: "Indíce CFX",
        bestRate: "Melhor taxa",
        side: "Sentido",
        rates: {
          title: "Taxas",
          accepted: "Aceito",
          declined: "Recusado"
        }
      }
    },
    pdf: {
      title: "Relatório",
      accepted: "Aceito",
      decliend: "Recusado"
    }
  }
};
    
const initialLocale = localStorage.getItem('userLanguage') || 'en';

const i18n = new VueI18n({
  locale: initialLocale,
  fallbackLocale: 'en',
  messages,
});

export default i18n;
